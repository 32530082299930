<template>
  <div v-frag>
    <v-container class="my-10">
      <v-row justify="center">
        <v-col cols="12" sm="6">
          <v-card min-height="360" height="100%">
            <v-card-title>
              <v-icon color="primary" class="mr-4">mdi-account-key</v-icon>
              <span>{{ $t('addNewPassword') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="px-6 pt-6">
              <v-form v-model="loginFormIsValid">
                <v-text-field
                  v-model="userData.password"
                  :aria-label="$t('password')"
                  :label="$t('password')"
                  :rules="inputRules.required"
                  :type="showPass1 ? 'text' : 'password'"
                  filled
                  counter
                  :append-icon="showPass1 ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPass1 = !showPass1"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="userData.passwordConfirm"
                  :aria-label="$t('newPasswordConfirm')"
                  :label="$t('newPasswordConfirm')"
                  :rules="inputRules.required"
                  :type="showPass2 ? 'text' : 'password'"
                  filled
                  counter
                  :append-icon="showPass2 ? 'mdi-eye-off' : 'mdi-eye'"
                  @click:append="showPass2 = !showPass2"
                  required
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="px-6 pb-6">
              <v-spacer></v-spacer>
              <v-btn class="primary" @click="SetPassword" :disabled="!allRulesAreValid">{{ $t('button.send') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-card>
            <v-card-title>
              <span>{{ $t('checkPassword') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-list>
                <template v-for="(item, index) in checkList">
                  <v-list-item :key="index" class="px-0">
                    <v-list-item-avatar>
                      <v-icon :color="item.isValid ? 'success' : 'grey lighten-1'">
                        {{ item.isValid ? 'mdi-check-circle' : 'mdi-close-circle-outline' }}
                      </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.message }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- RESPONSE DIALOGS START -->
    <v-dialog v-model="loadingDialog.visible" width="90%" max-width="340" :persistent="true">
      <loading-dialog v-bind:loading-message="loadingDialog.message" />
    </v-dialog>
    <v-dialog v-model="responseDialog.visible" width="90%" max-width="340" :persistent="true">
      <response-dialog v-bind:response-object="responseDialog.response"
                       v-on:close-dialog="closeResponseDialog"/>
    </v-dialog>
    <!-- RESPONSE DIALOGS END -->
  </div>
</template>

<script>
import LoadingDialog from 'src/components/dialogs/LoadingDialog.vue';
import ResponseDialog from 'src/components/dialogs/ResponseDialog.vue';

export default {
  name: 'ResetPassword',
  components: {
    LoadingDialog, ResponseDialog,
  },
  mounted() {
    //
  },
  props: {
    vs: {
      type: String,
      default: 'nothing',
    },
  },
  data() {
    return {
      showPass1: false,
      showPass2: false,
      loginFormIsValid: false,
      forgotPassFormIsValid: false,
      userData: {
        email: '',
        password: '',
        passwordConfirm: '',
      },
      inputRules: {
        required: [(v) => !!v || this.$t('inputMessages.requiredField')],
        noSpecialChar: [
          (v) => {
            return /^[a-zA-Z0-9-. öÖüÜóÓőŐúÚéÉáÁűŰíÍ]*$/.test(v) || this.$t('inputMessages.noSpecialChar')
          },
        ],
      },
      passwordRules: [
        { regex: /[a-z]+/ },
        { regex: /[A-Z]+/ },
        { regex: /.{8,}/ },
        { regex: /[0-9]+/ },
        // { regex: /[!@#$%*()_+\-={}?]/ },
      ],
      checkList: [
        { message: 'Min. egy kisbetű', isValid: false },
        { message: 'Min. egy nagybetű', isValid: false },
        { message: 'Min. 8 karakter', isValid: false },
        { message: 'Min. egy szám', isValid: false },
        // { message: 'Min. egy speciális karakter: !@#$%*()_+-={}?', isValid: false },
        { message: 'A két jelszó megegyezik', isValid: false },
      ],
      loadingDialog: {
        visible: false,
        message: 'loadingMessages.INPROGRESS_SETPASSWORD',
      },
      responseDialog: {
        visible: false,
        response: {
          error: false,
          data: [],
          message: 'successMessages.SUCCESS_REPAIR_FORM',
        },
      },
    };
  },
  methods: {
    async SetPassword() {
      this.openLoadingDialog();
      const response = await this.$store.dispatch('SetPassword', { vs: this.vs, form: { newPassword: this.userData.password } });
      // console.log('SetPassword response: ', response);
      if (response === 200) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>Sikeres beküldés!</b></div>'
          + '<div class="headline mb-4">A jelszó megváltoztatása sikeresen megtörtént.</div><div>Köszönjük megkeresését!</div>';
      } else if (response === 204) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>204</b></div>'
          + '<div class="headline mb-4">Hiba</div><div>Köszönjük megkeresését!</div>';
      } else if (response === 400) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>400 Váratlan hiba történt!</b></div>'
          + '<div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Dolgozunk a hiba elhárításán!</div><div>Köszönjük türelmét!</div>';
      } else if (response === 404) {
        this.responseDialog.response.message = '<div class="display-1 mb-6"><b>404 Váratlan hiba történt!</b></div>'
          + '<div class="headline mb-4">A folyamat során váratlan hiba lépett fel!<br>Dolgozunk a hiba elhárításán!</div><div>Köszönjük türelmét!</div>';
      } else {
        this.responseDialog.response.message = this.ERROR_MESSAGES.FORM_FAIL_MESSAGE;
      }
      this.controlDialogs();
    },
    passwordsFilled() {
      return (this.userData.password !== '' && this.userData.passwordConfirm !== '');
    },
    passwordMatch() {
      return (this.userData.password === this.userData.passwordConfirm);
    },
    openLoadingDialog() {
      this.loadingDialog.visible = true;
    },
    closeLoadingDialog() {
      this.loadingDialog.visible = false;
    },
    setupResponseDialog(message) {
      this.responseDialog.response.message = message;
    },
    openResponseDialog() {
      this.responseDialog.visible = true;
    },
    closeResponseDialog() {
      this.responseDialog.visible = false;
      this.$router.push({ name: 'home' });
      window.location.reload();
    },
    controlDialogs() {
      setTimeout(this.closeLoadingDialog, 3000);
      setTimeout(this.openResponseDialog, 3200);
    },
  },
  watch: {
    'userData.password': function (newVal) {
      this.passwordRules.forEach((item, index) => {
        this.checkList[index].isValid = item.regex.test(newVal);
      });
      if (this.passwordsFilled()) {
        this.checkList[4].isValid = this.passwordMatch();
      }
    },
    'userData.passwordConfirm': function (newVal) {
      if (this.passwordsFilled()) {
        this.checkList[4].isValid = this.passwordMatch();
      }
    },
  },
  computed: {
    allRulesAreValid() {
      const allRules = this.checkList.findIndex((item) => !item.isValid);
      return (this.loginFormIsValid && allRules < 0);
    },
  },
};
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./ForgotPasswordPage.vue?vue&type=template&id=70cb83fd&scoped=true&"
import script from "./ForgotPasswordPage.vue?vue&type=script&lang=js&"
export * from "./ForgotPasswordPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70cb83fd",
  null
  
)

export default component.exports
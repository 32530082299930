<template>
  <div v-frag>
    <hero-title title="forgotPassword" subtitle=" "></hero-title>
    <reset-password :vs="vs"></reset-password>
  </div>
</template>

<script>
import HeroTitle from '../../../components/titles/HeroTitle.vue';
import ResetPassword from '../../../components/auth/ForgotPassword/ResetPassword.vue';

export default {
  name: 'ForgotPasswordPage',
  components: {
    HeroTitle, ResetPassword,
  },
  props: {
    //
  },
  beforeMount() {
    if (this.$route.params.vs) {
      this.vs = this.$route.params.vs;
    }
  },
  data() {
    return {
      vs: '',
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
